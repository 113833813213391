import React from 'react';
import LandingPage from "./pages/landindingPage/LandingPage";
import {Helmet} from "react-helmet";
import {Route, Routes} from "react-router-dom";
import Tandc from "./pages/tandc/Tandc";

function App() {
  return (
    <div className="App">
        <Helmet>
            <title>COYD</title>
            <meta name="description" content="Get a CHEF whenever you need from one-time gigs to monthly subscription" />
            <meta name="keywords" content="COYD, chef on your demand, chef near me, book chef, chef service, cook booking, find cook" />
        </Helmet>
        <Routes>
            <Route path={"/"} element={<LandingPage/>}/>
            <Route path={"/tandc"} element={<Tandc/>}/>
        </Routes>

    </div>
  );
}

export default App;
