const Tandc = () => {
  return(
      <div className="tandc">
          <h1>Terms & Conditions</h1>
          <div dir="ltr" >
              <table>
                  <colgroup>
                      <col width="605"/>
                  </colgroup>
                  <tbody>
                  <tr>
                      <td>
                          <p dir="ltr">
                              Welcome to COYD ("Service", "we", "us", "our"). These
                              Terms and Conditions govern your
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              use of our service, including bookings for one-time,
                              one-day, weekly, fifteen-day, and monthly subscriptions.
                              By using our service, you agree to these terms. If you
                              do not agree with any part of these terms, please do not
                              use our service.
                          </p>
                          <h5 dir="ltr">
                              1. Service
                          </h5>
                          <p dir="ltr">
                              The Platform is for your personal and non-commercial use
                              only, unless otherwise
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              agreed upon between you and COYD on in accordance with
                              the terms of a separate
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              agreement. Please note that the Platform is intended for
                              use only within India.
                          </p>
                          <p dir="ltr">
                              <b>1.1.</b> One-Time and One-Day Bookings: These bookings have
                              a base price of ₹ XXX.
                          </p>
                          <p dir="ltr">
                              Additional charges may apply based on the time the chef
                              spends preparing food in your
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              kitchen.
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              <b>1.2</b> Weekly, Fifteen-Day, and Monthly Subscriptions:
                              These subscriptions have a fixed price depending on the
                              number of people to be served. The service includes a
                              maximum cooking time, which varies depending on the
                              number of customers.
                          </p>
                          <h5 dir="ltr">
                              <b>2.Pricing and Payments</b>
                          </h5>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              <b> 2.1</b> One-Time and One-Day Bookings: You will be charged
                              a base price of ₹XXXX plus
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              any additional time-based charges as per the chef’s
                              cooking duration.
                          </p>
                          <p dir="ltr">
                              <b>2.2</b> Subscriptions: Subscription fees are fixed and
                              based on the number of people to be served. Payment for
                              subscriptions must be made in advance.
                          </p>
                          <p dir="ltr">
                              <b>2.3</b> Additional Charges: Any additional services or
                              ingredients beyond the standard offering may incur extra
                              charges, which will be communicated beforehand.
                          </p>
                          <h5 dir="ltr">
                              <b>3.Maximum Cooking Time</b>
                          </h5>
                          <p dir="ltr">
                              <b>3.1</b> The maximum cooking time allowed per visit is
                              dependent on the number of people being served. Details
                              of the maximum cooking time will be provided at the time
                              of subscription.
                          </p>
                          <h5 dir="ltr">
                              <b>4.Cancellation Policy</b>
                          </h5>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              <b>4.1</b> One-Time and One-Day Bookings: These bookings are
                              non-refundable.
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>

                          <p dir="ltr">
                              <b>4.2</b> Weekly, Fifteen-Day, and Monthly Subscriptions: Customer
                              can cancel these subscriptions under the following
                              conditions: -
                          </p>
                          <br/>
                          <p dir="ltr">
                              - Weekly Subscription:
                          </p>
                          <p dir="ltr">
                           Complete refund if subscription status in CONFIRM stage.
                          </p>
                          <p dir="ltr">
                            Partial refund depend depend on number of days left.
                          </p>
                          <br/>
                          <p dir="ltr">
                              - Fifteen Days Subscription:
                          </p>
                          <p dir="ltr">
                            Complete refund if subscription status in CONFIRM stage.
                          </p>
                          <p dir="ltr">
                            Partial refund depend depend on number of days left.
                          </p>

                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>

          <p dir="ltr">
              - Monthly Subscription:
          </p>
          <p dir="ltr">
              Complete refund if subscription status in CONFIRM stage.
          </p>
          <p dir="ltr">
              Partial refund depend depend on number of days left.
          </p>
          <br/>
          <p dir="ltr">
              <b>4.3</b> Refunds for cancelled subscriptions will be prorated based on the
              remaining days of the subscription period after the effective cancellation
              date.
          </p>
          <h5 dir="ltr">
              <b>5. Chef Conduct and Customer Responsibilities</b>
          </h5>
          <p dir="ltr">
              <b>5.1</b> Our chefs are professionals and are expected to maintain high standards
              of cleanliness and conduct while in your kitchen.
          </p>
          <p dir="ltr">
              <b>5.2</b> Customers are responsible for providing a clean and safe kitchen
              environment for the chef to work in.
          </p>
          <p dir="ltr">
              <b>5.3</b> Any specific dietary requirements or food allergies must be
              communicated to us at the time of booking.
          </p>
          <p dir="ltr">
              <b>5.4</b> Chefs are not responsible for washing utensils used during food
              preparation. Customers should ensure all necessary utensils are clean and
              available before the chef arrives.
          </p>
          <h5 dir="ltr">
              <b>6.</b>  Limitation of Liability
          </h5>
          <p dir="ltr">
              <b>6.1</b> We are not responsible for any damage or loss of property caused by the
              chef during their service, except in cases of gross negligence or wilful
              misconduct.
          </p>
          <p dir="ltr">
              <b>6.2</b> We are not liable for any health issues arising from the consumption of
              food prepared by our chefs, especially if dietary requirements or allergies
              were not communicated in advance.
          </p>
          <h5 dir="ltr">
              <b>7.</b>   Data Use and Consent
          </h5>
          <p dir="ltr">
              <b>7.1</b> Data Collection: We collect personal information necessary for booking
              and providing our services. This may include your name, contact details,
              dietary preferences, and payment information.
          </p>
          <p dir="ltr">
              <b>7.2</b> Data Use: Your data is used to facilitate bookings, process payments,
              improve our services, and communicate with you about your bookings and our
              offers.
          </p>
          <p dir="ltr">
              <b>7.3</b> Data Sharing: We do not share your personal data with third parties
              except where necessary to provide our service, comply with the law, or
              protect our rights.
          </p>
          <p dir="ltr">
              <b>7.4</b> Consent: By using our service, you consent to the collection, use, and
              sharing of your data as described in these terms. You may withdraw your
              consent at any time by contacting us, but this may affect your ability to
              use our services.
          </p>
          <div dir="ltr" >
              <table>
                  <colgroup>
                      <col width="605"/>
                  </colgroup>
                  <tbody>
                  <tr>
                      <td>
                          <p dir="ltr">
                              <b>8.</b> Changes to Terms and Conditions
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              <b>8.1</b> We reserve the right to modify these terms at any
                              time. Any changes will be effective immediately upon
                              posting on our website.
                          </p>
                          <p dir="ltr">
                              <b>8.2</b>It is your responsibility
                              to review these terms
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              periodically. Continued use of our services constitutes
                              your acceptance of any changes.
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <h5 dir="ltr">
                              <b>9.</b>  Governing Law
                          </h5>
                          <p dir="ltr">
                              <b>9.1</b> These terms and conditions are governed by and
                              construed in accordance with the laws of COYD.
                          </p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p dir="ltr">
                              By using our service, you acknowledge that you have
                              read, understood, and agree to be bound by these terms
                              and conditions.
                          </p>
                          <p dir="ltr">
                              For any questions or concerns, please contact us at &nbsp;
                              <a href={"mailto:hello@coyd-service.com"}>hello@coyd-service.com</a>
                          </p>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>
          <br/>
      </div>
  )
}
export default Tandc;