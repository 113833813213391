import '../../styles/global.scss';
import Indus from '../../assets/images/indusLogo.svg';
import Ios from '../../assets/images/appStore.svg';
import Android from '../../assets/images/androidLogo.svg';
import Logo from '../../assets/images/Logo.svg';
import Login from '../../assets/images/Login.png';
import Landing_detail from '../../assets/images/Landing_detail.png';
import BookingForm from '../../assets/images/BookingForm.png';
import Subscription from '../../assets/images/Subscription.png';
import HeroAnimation from "../../assets/lottie/HeroAnimation";
import {Link} from "react-router-dom";

const LandingPage = () => {
    return (
        <div className="landingPage-wrapper">
            {/*1st section*/}
            <section className="hero-section">
                <img src={Logo} alt="logo" className="hero-logo"/>
                <div className="hero-content">
                    <div className="hero-left">
                        <p className="content">Get a CHEF whenever you need from one-time gigs to monthly
                            subscription</p>
                        <section className="store-logo">
                            {/*<img src={Ios} alt="ios"/>*/}
                            <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.coyd.app&amp;hl=en_IN&amp;gl=US")} src={Android} alt="android"/>


                            <img onClick={()=>window.open("https://www.indusappstore.com/details/indus/1085732")} src={Indus} alt="indus"/>

                        </section>
                    </div>
                    <HeroAnimation/>
                </div>
            </section>
            {/*2nd section*/}
            <section className="screens-section">
                <p>Explore the widest range of delicious food right at home.<br/> With COYD App!

                </p>
                <img src={Login} alt="login"/>
            </section>
            {/*3rd section*/}
            <section className="screens-section reverse">
                <p>Find trained and professional chef near your area</p>
                <img src={Landing_detail} alt="Landing_detail"/>
            </section>
            {/*4th section*/}
            <section className="screens-section">
                <p>Book a trusted chef at your doorstep and enjoy hot, delicious meals in the comfort at home.</p>
                <img src={BookingForm} alt="BookingForm"/>
            </section>
            {/*5th section*/}
            <section className="screens-section reverse">
                <p>Enjoy safe and secure payments powered by trusted merchants Experience the same convenience, trust,
                    and security for all your transactions.</p>
                <img src={Subscription} alt="Subscription"/>
            </section>
            {/*Footer*/}
            <footer>
                <section className="contact-details">
                    <p>Contact Us:</p>
                    <p>BACFRON TECHNOLOGIES PRIVATE LIMITED
                        105 FLOOR 1
                        SHAFT-2 NA WING E
                        PUNE CITY
                        LOHOGAON
                        PUNE-411047
                        MAHARASHTRA</p>
                    <a href="tel:+918296265704">+918296265704</a>
                    <a href="mailto:hello@coyd-service.com">hello@coyd-service.com</a>
                    <Link to={"/tandc"}>Terms & Conditions</Link>
                </section>
                <section className="store">
                    {/*<img src={Ios} alt="ios"/>*/}

                        <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.coyd.app&amp;hl=en_IN&amp;gl=US")} src={Android} alt="android"/>


                        <img onClick={()=>window.open("https://www.indusappstore.com/details/indus/1085732")} src={Indus} alt="indus"/>

                </section>
            </footer>
        </div>
    )
}

export default LandingPage;