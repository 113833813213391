import anim from "./hero-animation.json";
import Lottie from "lottie-react";



const HeroAnimation = () => {

    return (
        <Lottie animationData={anim} className="hero-lottie"/>
    )
}
export default HeroAnimation;